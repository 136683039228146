import api from '../api';

export async function getAllows() {
  return api.get('user/allows');
}

export async function getAllowedModules() {
  return api.get('user/allowedModules');
}

export async function getAllUsers(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'name',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('user/listUsers', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getUser(username) {
  return api.get(`user/listUser/${encodeURIComponent(username)}`);
}

export async function disableUser(username) {
  return api.put(`user/disableUser/${encodeURIComponent(username)}`);
}

export async function resetPassword(username) {
  return api.put(`user/resetPassword/${encodeURIComponent(username)}`);
}

export async function deleteUser(username) {
  return api.put(`user/deleteUser/${encodeURIComponent(username)}`);
}

export async function enableUser(username) {
  return api.put(`user/enableUser/${encodeURIComponent(username)}`);
}

export async function getProfilesFromUser(username) {
  return api.get(`user/getGroupsFromUser/${encodeURIComponent(username)}`);
}

export async function addUserToGroup(userName, groupName) {
  const payload = {
    userName,
    groupName
  }

  return api.put(`user/addUserToGroup/${encodeURIComponent(userName)}`, payload);
}

export async function removeUserFromGroup(userName, groupName) {
  const payload = {
    userName,
    groupName
  }

  return api.put(`user/removeUserFromGroup/${encodeURIComponent(userName)}`, payload);
}

export async function getAvatarURL(username) {
  if (!username) {
    username = 'me';
  }

  const response = await api.get(`/user/avatarImage/${username}`, {responseType: 'blob'});
  return response.data;
}

export async function postAvatar(base64Image, username) {
  if (!username) {
    username = 'me';
  }

  const response = await api.post(`/user/avatarImage/${username}`, {image: base64Image});
  return response.data;
}

export async function postMeName(name) {
  const response = await api.post(`/user/name/me`, {name});
  return response.data;
}


export async function updateUserAttributes(userName, userAttributes) {
  return api.put(`user/updateUserAttributes/${encodeURIComponent(userName)}`, userAttributes);
}

export async function createUser(userName, userAttributes) {
  return api.post(`user/createUser/${encodeURIComponent(userName)}`, userAttributes);
}

export async function getUserAttributes(userName) {
  return api.get(`user/getUserAttributes/${encodeURIComponent(userName)}`);
}

export async function findBranch(query, numOfRegisters = 6) {
  return api.get('user/branches', {
    params: {
      query,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function findUserGroups(description, numOfRegisters = 6) {
  return api.get('user/usergroups', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function findCustomers(query, numOfRegisters = 6) {
  return api.get('user/customers', {
    params: {
      query,
      order: 'name'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}