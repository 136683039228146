//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import PrintIcon from '@material-ui/icons/Print';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RefreshIcon from '@material-ui/icons/Refresh';
//internall
import { getRtpis, getAllows, getRtpiReport, getWarrantyLevelReport, rtpiFlagPrint } from 'services/rtpi/rtpiPrint/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { findBranch } from 'services/rtpi/rtpiPrint/service';
import { showReport } from 'utils/reportUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterRtpi } from '..';
//style
import { IconContainer } from './styled';
import { showDialog } from 'components/Dialog';
import { AttrName, Attr } from './styled'
import { Button } from '@material-ui/core';
import { showConfirm } from 'components/AlertDialog';

export default function Intention() {
  const message = useSelector(state => state.message.data);
  
  const [rtpis, setRtpis] = useState();
  const [allows, setAllows] = useState({});
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");

  const { filter, setFilter } = useContext(FilterRtpi);

  const debouncedSearchTerm = useDebounce(filter.search);
  const debouncedSearchBranch = useDebounce(searchBranch);
  
  const dispatch = useDispatch();
  let closeDialogHandler;

  const loadBranch = useCallback((searchValue) => {
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedSearchBranch)
  },[debouncedSearchBranch, loadBranch]);

  function handleClickFlagPrint(row) {
    const dialog = showDialog(flagPrintDialog(row), "Marcar esta RTPI como impressa?");
    closeDialogHandler = dialog.removeDialog;
  }

  async function flagPrintHandler(confirm, data) {
    if (confirm) {
      if (!await showConfirm('Deseja realmente marcar a RTPI como impressa?')) {
        return;
      }
      
      dispatch(startRequest());
      rtpiFlagPrint(data.protheusBranch, data.protheusCode, data.revision).then(res => {
        refreshHandler();
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
        if (closeDialogHandler) {
          closeDialogHandler();
        }
      });
    } else {
      if (closeDialogHandler) {
        closeDialogHandler();
      }
    }
  }

  function flagPrintDialog(data) {
    return (
      <>
        <AttrName>Filial:</AttrName>
        <Attr>{data.protheusBranch}</Attr>
        <AttrName>Código:</AttrName>
        <Attr>{data.protheusCode}</Attr>
        <AttrName>Produto:</AttrName>
        <Attr>{data.productDescription}</Attr>
        <AttrName>Revisão:</AttrName>
        <Attr>{data.revision}</Attr>

        <div style={{textAlign: 'right'}}>
          <Button onClick={() => flagPrintHandler(false)} color="secondary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => flagPrintHandler(true, data)} color="primary" autoFocus>
            Confirmar
          </Button>
        </div>
      </>
    )
  }

  function handleClickPrintRtpi(row) {
    if (row.protheusBranch && row.protheusCode) {
      dispatch(startRequest());
      getRtpiReport(row.protheusBranch, row.protheusCode).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  function handleClickPrintWarrantyLevel(row) {
    if (row.protheusBranch && row.protheusCode) {
      dispatch(startRequest());
      getWarrantyLevelReport(row.protheusBranch, row.protheusCode).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  const columns = [
    {
      maxWidth: "15%",
      name: 'Filial',
      selector: 'protheusBranch',
      sortable: true,
    },
    {
      maxWidth: "15%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "35%",
      name: 'Produto',
      selector: 'productDescription',
      sortable: true,
    },
    {
      maxWidth: "15%",
      name: 'Revisão',
      selector: 'revision',
      sortable: true,
    },
    {
      maxWidth: "20%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <IconContainer>
          <TableAction 
            title={"Marcar como impressa"} 
            disabled={!allows.put} 
            onClick={() => handleClickFlagPrint(row)}
            isLink={false}
          >
            <DoneOutlineIcon />
          </TableAction>
          <TableAction 
            title={"Imprimir RTPI"} 
            onClick={() => handleClickPrintRtpi(row)}
            isLink={false}
          >
            <PrintIcon />
          </TableAction>
          <TableAction 
            title={"Níveis de Garantia"} 
            onClick={() => handleClickPrintWarrantyLevel(row)}
            isLink={false}
          >
            <ReceiptIcon />
          </TableAction>
        </IconContainer>
    }
  ];

  const actions = [
    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getRtpis(debouncedSearchTerm, perPage, filter.page, sort, filter.branch?.value?.protheusCode).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setRtpis(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, dispatch, filter.page, filter.branch, perPage, sort, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  function handleBranchChange(data) {
    if (data) {
      setFilter({page: 1, branch: data});
    } else {
      setFilter({page: 1, branch: null});
      loadBranch("");
    }   
  }

  return (
    <>
      <ContentPanel message={message} title="RTPI's - Não impressas" actions={actions}>
        <div style={{width: '100%', padding: '0 0 10px 0'}}>
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={filter.branch}
            label="Buscar filial"
            optionSelected={(option, value) => option.value.protheusCode === value.value.protheusCode}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleBranchChange(value)}
            onChange={(event) => setSearchBranch(event.target.value)}
            options={optionsBranch}
            name="branch"
            hasWarning={false}
          />
        </div>
        <GridContainer>
          <InputText
            xs={12}
            name="search"
            label="Pesquisar"
            type="text"
            placeholder="Pesquisar"
            value={filter.search}
            onChange={(e) => setFilter({...filter, page: 1, search: e.target.value})}
            hasWarning={false}
          />
        </GridContainer>

        <DataTableReact
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          columns={columns}
          data={rtpis}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}